<!--Start portfolio-->
<section id="works" class="section">
  <!-- Filtering -->
  <div class="title-box text-center">
    <h2 class="title">Our Work</h2>
  </div>


  <div class=" col-md-12 text-center">
    <!-- Filtering -->
    <ul class="filtering">
      <li class="filter" data-filter="all" (click)="allFiltersFunction()">All Filters</li>
      <li class="filter" data-filter="airFilter" (click)="airFiltersFunction()">Air Filters</li>
      <li class="filter" data-filter="oilFilter" (click)="oilFiltersFunction()">Oil Filters</li>
      <li class="filter" data-filter="hydraulicFilter" (click)="hydraulicFiltersFunction()">Hydraulic Filters</li>
      <li class="filter" data-filter="panelFilter" (click)="panelFiltersFunction()">Panel Filters</li>
      <li class="filter" data-filter="waterFilter" (click)="waterFiltersFunction()">Water Filters</li>
      <li class="filter" data-filter="fuelFilter" (click)="fuelFiltersFunction()">Fuel Filters</li>
    </ul>
  </div>
  <div class="m-30">
    <div class="info text-center" *ngIf="allFilters">
      <h4>All Filters</h4>
      <p>We focus on the most advanced technology through global collaboration & development to provide the highest
        quality air filtration products at the lowest possible cost.</p>
    </div>
    <div class="info text-center" *ngIf="airFilters">
      <h4>Air Filters</h4>
      <p>Clean air is essential to the performance and life of your engine. Proper air filtration is important because a
        small amount of soot or dust can cause a tremendous amount of engine damage. IN MODERN VEHICLES the function of
        electronic parts located between the suction and the combustion chamber may also be significantly impaired. </p>
      <p>ARK-FILTER air filters are the end of the road for all dirt particles. No matter whether its dust, pollen,
        sand, soot or even water droplets - thanks to the highest degree of dirt separation and high mechanical
        stability, ARK-FILTER air filters filter even the smallest particles out of the intake air. This enables an
        optimum composition of the fuel-air ratio and ensures that the engine performance is consistent.</p>
      <h4>AIR FILTER MAINTENCE</h4>
      <p>In order to guarantee performance, air filters must be checked regularly, as a blocked filter will impede the
        air supply to the engine. The possible consequence of this is: the fuel may not be completely burned and may in
        part be discarded without being used. Here is another hallmark of ARK-FILTER air filters: the high dust holding
        capacity ensures reliability throughout the entire service interval.</p>
    </div>
    <div class="info text-center" *ngIf="oilFilters">
      <h4>Oil Filters</h4>
      <p>More and more performance, less and less consumption - in order to meet the high demands placed on modern
        engines, automobile manufacturers demand the absolute highest levels of performance from the engine components
        used. Dirty oil can cause catastrophic damage to your engine. By using the proper lube filter for your engine,
        you can minimize the possibility of costly downtime and repairs.</p>
      <p>If you want to play it safe when choosing an oil filter, choose ARK-FILTER. They are designed for modern
        high-performance oils and can stand up to even the most demanding technical challenges. We have two type of
        filters as per their applications. </p>
      <h4 class="text-left">ARK FILTERS spin-on oil filters</h4>
      <p class="text-left m-b-0">By choosing ARK-FILTER spin-on oil filters, you get first-class quality and
        problem-free handling. The housing and filter element of the spin-on filter form a perfectly coordinated unit,
        which is fully replaced during maintenance.</p>
      <p class="text-left m-b-0">ARK-FILTER's extensive technical expertise becomes particularly evident when you see
        the special features included with each filter type.</p>
      <p class="text-left m-b-0"><b>Return stop valve:</b> ensures that the filter and oil channels do not empty when
        the engine is switched off and that oil is readily available when the engine is started.</p>
      <p class="text-left m-b-0"><b>Bypass valve:</b> ensures that oil is available when the engine is cold started or
        when the service interval has been significantly exceeded and the oil filter is very dirty.</p>
      <p class="text-left"><b>Release devices:</b> enable quick and easy removal of the spin-on oil filter.</p>
      <h4 class="text-left">N-FILTER filter element</h4>
      <p class="text-left m-b-0">ARK-FILTER high performance filter elements have been specially developed for use in
        modern engines. Thanks to outstanding dirt holding capacity, high material resistance and filter fineness
        perfectly matched to the relevant vehicle model, our filter inserts can cope with intensive use and deliver
        superb results.</p>
      <p class="text-left m-b-0">The most modern filter media prolong service intervals and will continue to meet the
        technical requirements of high-performance engines in future.</p>
    </div>
    <div class="info text-center" *ngIf="hydraulicFilters">
      <h4>Hydraulic filters</h4>
      <p>Clean hydraulic oil is the strength of heavy duty application machines .Dirty hydraulic oil can harm the
        hydraulic system and user can bear a lot of expense. So a high quality ARK-FILTER Hydraulic filter can save you
        a lot of money and time. The filter fineness is in the µm range. Special quality paper with all the required
        specifications and materials is the key features of the ARK filters. High temperature resistance of the filter
        material and paper enhances its life.</p>
    </div>
    <div class="info text-center" *ngIf="panelFilters">
      <h4>Panel filters</h4>
      <p>ARK Filter excelled its expertise in the manufacturing of the Panel Filters for different applications.
        Industry requires panel filters the for Clean Rooms for their most expensive working machines.ARK FILTER have
        the capability of manufacturing panel filters of different specifications according to their usage. Micro-glass
        fiber media, Polyester based media, spun bound and needle felt are some of the materials to be used in these
        filters.ARK FILTER can design and engineer these filters according to customers need.</p>
    </div>
    <div class="info text-center" *ngIf="waterFilters">
      <h4>Water filters</h4>
      <p>ARK Filters water filters are made from the premium quality materials. Water is an important medium in all the
        Power generation systems. So a well treated water can made an engine the best performing unit.ARK Filters water
        filters are available in all the required varieties for the local needs.</p>
    </div>
    <div class="info text-center" *ngIf="fuelFilters">
      <h4>Fuel filters</h4>
      <p>Modern high performance injection systems place high demands on the quality and purity of fuel. ARK-FILTER fuel
        filters ensure that dirt particles and any water contained in the fuel do not damage the engine.</p>
      <p>ARK-FILTER fuel filters are also adapted to the high demands of sensitive injection assemblies, as well as the
        specific properties of alternative fuels, for example biodiesel. By using extremely fine filter media, the fuel
        is reliably freed of undesirable dirt particles. Our filters protect injection systems against wear and
        corrosion and thus help maintain the smooth running of the entire engine. An effect which is also positively
        reflected in the maintenance costs.</p>
    </div>
  </div>
  <div class="work-main">
    <!-- Work Grid -->
    <ul class="work-grid">
      <!-- Work Item -->
      <li class="work-item thumnail-img mix panelFilter">
        <div class="work-image">
          <img src="../../../assets/images/works/img1.jpg" alt="thumbnail">

          <!--Hover link-->
          <div class="hover-link">
            <a href="single-work.html">
              <i class="fa fa-link"></i>
            </a>

            <a href="../../../assets/images/works/img1.jpg" class="popup-image">
              <i class="fa fa-plus"></i>
            </a>
          </div>
          <!--End link-->

          <!--Hover Caption-->
          <div class="work-caption">
            <h4>Project Title</h4>
            <p>Photography</p>
          </div>
          <!--End Caption-->

        </div> <!-- /.work-image-->
      </li>
      <!--End Work Item -->

      <!-- Work Item -->
      <li class="work-item thumnail-img mix airFilter hydraulicFilter waterFilter">
        <div class="work-image">
          <img src="../../../assets/images/works/img2.jpg" alt="thumbnail">

          <!--Hover link-->
          <div class="hover-link">
            <a href="single-work.html">
              <i class="fa fa-link"></i>
            </a>

            <a href="../../../assets/images/works/img2.jpg" class="popup-image">
              <i class="fa fa-plus"></i>
            </a>
          </div>
          <!--End link-->

          <!--Hover Caption-->
          <div class="work-caption">
            <h4>Project Title</h4>
            <p>Photography</p>
          </div>
          <!--End Caption-->

        </div> <!-- /.work-image-->
      </li>
      <!--End Work Item -->

      <!-- Work Item -->
      <li class="work-item thumnail-img mix panelFilter">
        <div class="work-image">
          <img src="../../../assets/images/works/img3.jpeg" alt="thumbnail">

          <!--Hover link-->
          <div class="hover-link">
            <a href="single-work.html">
              <i class="fa fa-link"></i>
            </a>

            <a href="../../../assets/images/works/img3.jpeg" class="popup-image">
              <i class="fa fa-plus"></i>
            </a>
          </div>
          <!--End link-->

          <!--Hover Caption-->
          <div class="work-caption">
            <h4>Project Title</h4>
            <p>Photography</p>
          </div>
          <!--End Caption-->

        </div> <!-- /.work-image-->
      </li>
      <!--End Work Item -->

      <!-- Work Item -->
      <li class="work-item thumnail-img mix panelFilter">
        <div class="work-image">
          <img src="../../../assets/images/works/img44.jpg" alt="thumbnail">

          <!--Hover link-->
          <div class="hover-link">
            <a href="single-work.html">
              <i class="fa fa-link"></i>
            </a>

            <a href="../../../assets/images/works/img44.jpg" class="popup-image">
              <i class="fa fa-plus"></i>
            </a>
          </div>
          <!--End link-->

          <!--Hover Caption-->
          <div class="work-caption">
            <h4>Project Title</h4>
            <p>Photography</p>
          </div>
          <!--End Caption-->

        </div> <!-- /.work-image-->
      </li>
      <!--End Work Item -->

      <!-- Work Item -->
      <li class="work-item thumnail-img mix airFilter hydraulicFilter fuelFilter">
        <div class="work-image">
          <img src="../../../assets/images/works/img55.jpg" alt="thumbnail">

          <!--Hover link-->
          <div class="hover-link">
            <a href="single-work.html">
              <i class="fa fa-link"></i>
            </a>

            <a href="../../../assets/images/works/img55.jpg" class="popup-image">
              <i class="fa fa-plus"></i>
            </a>
          </div>
          <!--End link-->

          <!--Hover Caption-->
          <div class="work-caption">
            <h4>Project Title</h4>
            <p>Photography</p>
          </div>
          <!--End Caption-->

        </div> <!-- /.work-image-->
      </li>
      <!--End Work Item -->

      <!-- Work Item -->
      <li class="work-item thumnail-img mix oilFilter hydraulicFilter fuelFilter">
        <div class="work-image">
          <img src="../../../assets/images/works/img66.jpg" alt="thumbnail">

          <!--Hover link-->
          <div class="hover-link">
            <a href="single-work.html">
              <i class="fa fa-link"></i>
            </a>

            <a href="../../../assets/images/works/img66.jpg" class="popup-image">
              <i class="fa fa-plus"></i>
            </a>
          </div>
          <!--End link-->

          <!--Hover Caption-->
          <div class="work-caption">
            <h4>Project Title</h4>
            <p>Photography</p>
          </div>
          <!--End Caption-->

        </div> <!-- /.work-image-->
      </li>
      <!--End Work Item -->

      <!-- Work Item -->
      <li class="work-item thumnail-img mix oilFilter">
        <div class="work-image">
          <img src="../../../assets/images/works/img7.jpeg" alt="thumbnail">

          <!--Hover link-->
          <div class="hover-link">
            <a href="single-work.html">
              <i class="fa fa-link"></i>
            </a>

            <a href="../../../assets/images/works/img7.jpeg" class="popup-image">
              <i class="fa fa-plus"></i>
            </a>
          </div>
          <!--End link-->

          <!--Hover Caption-->
          <div class="work-caption">
            <h4>Project Title</h4>
            <p>Photography</p>
          </div>
          <!--End Caption-->

        </div> <!-- /.work-image-->
      </li>
      <!--End Work Item -->

      <!-- Work Item -->
      <li class="work-item thumnail-img mix panelFilter">
        <div class="work-image">
          <img src="../../../assets/images/works/img8.jpeg" alt="thumbnail">

          <!--Hover link-->
          <div class="hover-link">
            <a href="single-work.html">
              <i class="fa fa-link"></i>
            </a>

            <a href="../../../assets/images/works/img8.jpeg" class="popup-image">
              <i class="fa fa-plus"></i>
            </a>
          </div>
          <!--End link-->

          <!--Hover Caption-->
          <div class="work-caption">
            <h4>Project Title</h4>
            <p>Photography</p>
          </div>
          <!--End Caption-->

        </div> <!-- /.work-image-->
      </li>
      <!--End Work Item -->

      <!-- Work Item -->
      <li class="work-item thumnail-img mix oilFilter">
        <div class="work-image">
          <img src="../../../assets/images/works/img9.jpeg" alt="thumbnail">

          <!--Hover link-->
          <div class="hover-link">
            <a href="single-work.html">
              <i class="fa fa-link"></i>
            </a>

            <a href="../../../assets/images/works/img9.jpeg" class="popup-image">
              <i class="fa fa-plus"></i>
            </a>
          </div>
          <!--End link-->

          <!--Hover Caption-->
          <div class="work-caption">
            <h4>Project Title</h4>
            <p>Photography</p>
          </div>
          <!--End Caption-->

        </div> <!-- /.work-image-->
      </li>
      <!--End Work Item -->

      <!-- Work Item -->
      <li class="work-item thumnail-img mix hydraulicFilter">
        <div class="work-image">
          <img src="../../../assets/images/works/img10.jpg" alt="thumbnail">

          <!--Hover link-->
          <div class="hover-link">
            <a href="single-work.html">
              <i class="fa fa-link"></i>
            </a>

            <a href="../../../assets/images/works/img10.jpg" class="popup-image">
              <i class="fa fa-plus"></i>
            </a>
          </div>
          <!--End link-->

          <!--Hover Caption-->
          <div class="work-caption">
            <h4>Project Title</h4>
            <p>Photography</p>
          </div>
          <!--End Caption-->

        </div> <!-- /.work-image-->
      </li>
      <!--End Work Item -->

      <!-- Work Item -->
      <li class="work-item thumnail-img mix airFilter">
        <div class="work-image">
          <img src="../../../assets/images/works/img11.jpg" alt="thumbnail">

          <!--Hover link-->
          <div class="hover-link">
            <a href="single-work.html">
              <i class="fa fa-link"></i>
            </a>

            <a href="../../../assets/images/works/img11.jpg" class="popup-image">
              <i class="fa fa-plus"></i>
            </a>
          </div>
          <!--End link-->

          <!--Hover Caption-->
          <div class="work-caption">
            <h4>Project Title</h4>
            <p>Photography</p>
          </div>
          <!--End Caption-->

        </div> <!-- /.work-image-->
      </li>
      <!--End Work Item -->

      <!-- Work Item -->
      <li class="work-item thumnail-img mix panelFilter">
        <div class="work-image">
          <img src="../../../assets/images/works/img12.jpg" alt="thumbnail">

          <!--Hover link-->
          <div class="hover-link">
            <a href="single-work.html">
              <i class="fa fa-link"></i>
            </a>

            <a href="../../../assets/images/works/img12.jpg" class="popup-image">
              <i class="fa fa-plus"></i>
            </a>
          </div>
          <!--End link-->

          <!--Hover Caption-->
          <div class="work-caption">
            <h4>Project Title</h4>
            <p>Photography</p>
          </div>
          <!--End Caption-->

        </div> <!-- /.work-image-->
      </li>
      <!--End Work Item -->
    </ul>

  </div>
</section>
<!--End portfolio-->
