<!--Start Section-->
<section class="section" style="padding: 0;">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="content-tab-1">
          <ul class="nav">
            <li class="active">
              <a aria-expanded="true" href="#tab-content-1" data-toggle="tab">
                <i class="fa fa-flag"></i>
                <h4>Our Priority</h4>
              </a>
              <div class="tab-arrow">
              </div>
            </li>
            <li>
              <a aria-expanded="true" href="#tab-content-2" data-toggle="tab">
                <i class="fa fa-cubes"></i>
                <h4>Who We Are</h4>
              </a>
              <div class="tab-arrow">
              </div>
            </li>
            <li>
              <a aria-expanded="true" href="#tab-content-3" data-toggle="tab">
                <i class="fa fa-institution"></i>
                <h4>Support</h4>
              </a>
              <div class="tab-arrow">
              </div>
            </li>
            <li>
              <a aria-expanded="true" href="#tab-content-4" data-toggle="tab">
                <i class="fa fa-pagelines"></i>
                <h4>Our Culture
                </h4>
              </a>
              <div class="tab-arrow">
              </div>
            </li>
            <li>
              <a aria-expanded="true" href="#tab-content-5" data-toggle="tab">
                <i class="fa fa-pie-chart"></i>
                <h4>Why Ark Filters</h4>
              </a>
              <div class="tab-arrow">
              </div>
            </li>
          </ul>
          <div class="tab-content-main">
            <div class="container">
              <div class="tab-content">
                <div class="tab-pane active in" id="tab-content-1">
                  <!-- Features Icon-->
                  <div class="col-md-6 margin-bottom-30">
                    <div class="tab1-features">
                      <div class="icon"> <i class="fa fa-star-o"></i> </div>
                      <div class="info">
                        <h4>Ultra Compact, High Performance</h4>
                        <p>We develop ultra impact high performance filters, One of the benefits of our filters is that unlike regular filters, you don't have to change it every year. In fact, a high flow filter can actually increase your horsepower by about 3 to 5 HP and increase your torque as well. </p>
                      </div>
                    </div>
                    <div class="tab1-features">
                      <div class="icon"> <i class="fa fa-codepen"></i> </div>
                      <div class="info">
                        <h4>FLEXIBLE INSTALLATION</h4>
                        <p>Our filters are easy to installed and maintenance cost is very low.</p>
                      </div>
                    </div>
                    <div class="tab1-features">
                      <div class="icon"> <i class="fa fa-heart-o"></i></div>
                      <div class="info">
                        <h4>Quality not quantity</h4>
                        <p>We know clean air is a must, especially in critical environments. S .A. ENGINEERING COMPANY manufactures quality commercial air filters and industrial air filters for your products that needs the most. </p>
                      </div>
                    </div>
                  </div>
                  <!--End features Icon-->
                  <!--  Start Carousel-->
                  <div class="col-md-6">
                    <div class="tab-carousel">
                      <div class="item"><img src="../../../assets/images/works/img4.jpg" alt=""></div>
                      <div class="item"><img src="../../../assets/images/works/img5.jpg" alt=""></div>
                      <div class="item"><img src="../../../assets/images/works/img6.jpg" alt=""></div>
                    </div>
                  </div>
                  <!-- End Carousel-->
                </div>
                <!-- End Tab content 1-->
                <!-- Start Tab content 2-->
                <div class="tab-pane" id="tab-content-2">
                  <div class="col-md-4">
                    <div class="tab2-services-box">
                      <div class="media">
                        <img src="../../../assets/images/services1.jpg" alt="services" />
                      </div>
                      <div class="services-info">
                        <h4>Our History</h4>
                        <p>S.A. ENGINEERING COMPANY is one of the largest filter manufacturing company in PAKISTAN.S.A. ENGINEERING CO. can manufacture as well as can TRADE in the FILTERS. It has a vast range of the filter products. ARK FILTERS are manufactured under the manufacturing facility of the MICRO-TECH AUTOMOTIVE INDUSTRY. ROCK-ONE FILTERS and MICRO-TECH FILTERS are manufactured by S.A. ENGINEERING COMPANY. S.A. ENGINEERING COMPANY and MICRO-TECH AUTOMOTIVE INDUSTRY are two SISTER CONCERNS. S.A. ENGINEERING CO. was established by a team of professionals who have the studies of FILTER ENGINEERING.</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="tab2-services-box">
                      <div class="media">
                        <img src="../../../assets/images/services2.jpg" alt="services" />
                      </div>
                      <div class="services-info">
                        <h4>What We Do</h4>
                        <p>We build up as company which can cope with the modern technical needs of the country in the FILTRATION field. Thus their quest for the achievement of the highest quality filter products laid its foundations. S .A. ENGINEERING COMPANY has all the needed infrastructure and machinery for developing and testing of the filters. S.A.ENGINEERING COMPANY has a diversified FILTER range, that is, AIR,OIL,FUEL,HYDURALIC,AIR/OIL SEPARATOR,HVAC,PANEL,STRAINERS are to name a few. One thing which make us different from our competitors is our diversified filter range. We can develop every type of product which carries the word of filter. </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="tab2-services-box">
                      <div class="media">
                        <img src="../../../assets/images/services3.jpg" alt="services" />
                      </div>
                      <div class="services-info">
                        <h4>Our Focus</h4>
                        <p>S.A.ENGINEERING CO. has goal, when we started to develop filters a decade ago was the same as it is today: to serve the others with quality product in reasonable price as soon as possible. S.A.ENGINEERING CO. always focus on to become a Pakistan larget filters provider company. S.A.ENGINEERING CO. also try to improving people’s lives through meaningful innovation. S.A.ENGINEERING CO. has all the needed technical stuffs to manufacture and develop new products. S.A.ENGINEERING CO. also provide branded products and services of superior quality and value that improve the lives of the world’s consumers. Get in touch with us for more information.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane" id="tab-content-3">
                  <div class="features-tab3">
                    <!--Start Features Left-->
                    <div class="col-md-4">
                      <div class="features-left m-bot-30">
                        <!--Features Item #1-->
                        <div class="features-item">
                          <div class="features-icon"> <i class="fa fa-phone"></i> </div>
                          <div class="features-info">
                            <h4>24/7 Call Support</h4>
                            <p>We are available on chat for 24/7 for our customer. Customer problem and their solution in our first priority. and Phone call is the quickest way to resolve the problem.
                            </p>
                          </div>
                        </div>
                        <!--Features Item #2-->
                        <div class="features-item">
                          <div class="features-icon"> <i class="fa fa-whatsapp"></i> </div>
                          <div class="features-info">
                            <h4>WhatsApp Chat Support</h4>
                            <p>If the metter is not urgent or not possible to have call with customer support in that case we are also facilitating our customer with Whatsapp Chat. So they can leave their messages in chatbox and our customer support team will answer those questions and concern on priority.
                            </p>
                          </div>
                        </div>
                        <!--Features Item #3-->
                        <div class="features-item">
                          <div class="features-icon"> <i class="fa fa-comment"></i> </div>
                          <div class="features-info">
                            <h4>Customer Sentiment Analysis</h4>
                            <p>Customer feedback and their sentiments means alot for us. Customer can share their review/feedback with us that will help us to improve our service better than before.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--End Features Left-->
                    <!--Start Features Image-->
                    <div class="col-md-4 hidden-xs m-bot-30">
                      <img src="../../../assets/images/device.jpg" alt="Features Image" />
                    </div>
                    <!--End Features Image-->
                    <!--Start Features Right-->
                    <div class="col-md-4">
                      <div class="features-right m-bot-30">
                        <!--Features Item #1-->
                        <div class="features-item">
                          <div class="features-icon"> <i class="fa fa-thumb-tack"></i> </div>
                          <div class="features-info">
                            <h4>Technical issues</h4>
                            <p>Technical Issue are the most important purpose of our customer support. if you find any fualt or face any issue with our product let us to know we will provide you the complete solution and it will also help us to improve us products.
                            </p>
                          </div>
                        </div>
                        <!--Features Item #2-->
                        <div class="features-item">
                          <div class="features-icon"> <i class="fa fa-envelope"></i> </div>
                          <div class="features-info">
                            <h4>News Letter</h4>
                            <p>
                              We believe our customer should be aware about the things which are currently producing for that We will send you the latest product information over the email address so that you can in touch with us and get the fully detail of our products.
                            </p>
                          </div>
                        </div>
                        <!--Features Item #3-->
                        <div class="features-item">
                          <div class="features-icon"> <i class="fa fa-truck"></i> </div>
                          <div class="features-info">
                            <h4>Order Dispatching Updates</h4>
                            <p>We belive in transperancy with our customer. You can check the status of you order by having call or chat with our customer care member. we will let you know when about the current status of you dispatched order.
                            </p>
                          </div>
                        </div>

                      </div>
                    </div>
                    <!--End Features Right -->
                  </div>
                </div>
                <div class="tab-pane" id="tab-content-4">
                  <div class="tab-content-4">
                    <div class="col-md-3">
                      <div class="tab4-services-box">
                        <div class="icon"><i class="fa fa-paw"></i> </div>
                        <div class="info">
                          <h4>Team-first</h4>
                          <p>Ark-Filters corporate culture hire people based on how well they fit with our
                            organization’s values and beliefs, then consider skills and experience. This often makes for
                            a happy workforce with proud and passionate employees.</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="tab4-services-box">
                        <div class="icon"><i class="fa fa-bolt"></i> </div>
                        <div class="info">
                          <h4>Progressive</h4>
                          <p>Ark-Filters offers an opportunity to redefine or clarify roles, goals and mission
                            statements. If you communicate well, welcome change and like trying new ideas, you may
                            succeed in progressive cultures.</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="tab4-services-box">
                        <div class="icon"><i class="fa fa-trophy"></i> </div>
                        <div class="info">
                          <h4>Rewarding Success</h4>
                          <p>Ark-Filters recognize and reward performance and achievements. We recognize every
                            person’s—not just top performers’—work throughout the year so no one gets excluded or
                            discouraged. We celebrate milestones and achievements publicly.</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="tab4-services-box">
                        <div class="icon"><i class="fa fa-child"></i> </div>
                        <div class="info">
                          <h4>Trusting staff</h4>
                          <p>Ark-Filters trust their employees to do their work well and offer people independence.
                            Because we believe in that when employees and employers share the same company vision, this
                            becomes increasingly possible.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane" id="tab-content-5">
                  <div class="tab-content-5">
                    <div class="col-md-6">
                      <div class="core-features">

                        <p>We are a Managed Service Provider focussed in providing filtering services for many years.
                          Our recommendations will always be in your best interests. We are passionate about continuous
                          improvement, leaving no stone unturned in the search for the best solutions to your
                          challenges. Our products are of the highest standard, with all possibilities explored.</p>

                        <ul class="list">
                          <li>Best Price- Best Service.</li>
                          <li>Save on costs.</li>
                          <li>High Quality Products.</li>
                          <li>After Sale Service.</li>
                          <li>Guarantee on all work done.</li>
                          <li>Managed Service Provider.</li>
                        </ul>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="devices-image">
                        <img src="../../../assets/images/device-desktop.jpg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--/.tab-content-main-->
        </div>
      </div>
    </div>
    <!--/.row-->
  </div>
  <!--/.container-->
</section>
<!--End Section-->
