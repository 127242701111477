<!--Start Page loader -->
<div id="pageloader">
  <div class="loader">
    <img src="../../../assets/images/progress.gif" alt='loader' />
  </div>
</div>
<!--End Page loader -->


<!--Start Navigation-->
<header id="header">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#main-menu">
          <span class="sr-only">Toggle navigation</span>
          <span class="fa fa-bars"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <!--Start Logo -->
        <div class="logo-nav">
          <a href="index.html">
            <img src="../../../assets/images/ark-filters.png" alt="Company logo" />
          </a>
        </div>
        <!--End Logo -->
        <div class="clear-toggle"></div>
        <div id="main-menu" class="collapse scroll navbar-right">
          <ul class="nav">

            <li class="active"> <a href="#home">Home</a> </li>

            <li> <a href="#about">About</a> </li>

            <li> <a href="#history">Story</a> </li>

            <li> <a href="#works">Product</a> </li>

            <li> <a href="#team">Team</a> </li>

            <li> <a href="#services">Capabilities</a> </li>

            <!-- <li> <a href="#blog">Blog</a></li> -->

            <li> <a href="#testimonials">Clients</a></li>

            <li> <a href="#contact">Contact</a> </li>

          </ul>
        </div><!-- end main-menu -->
      </div>
    </div>
  </div>
</header>
<!--End Navigation-->
