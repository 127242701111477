import { Component } from '@angular/core';
import {abc} from '../assets/js/owl-carousel/owl.carousel.js';

declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'ark-filters';

}
