
<!--Start Features-->
<section id="about" class="section">
  <div class="title-box text-center"><h2 class="title">OUR DEPARTMENTS
  </h2></div>
  <div class="container">
    <div class="row">

      <!-- Features Icon-->
      <div class="col-md-4">
        <div class="features-icon-box">

          <div class="features-icon">
            <i class="fa fa-leaf"></i>
          </div>

          <div class="features-info">
            <h4>R&D</h4>
            <p>Everything starts from the R&D department; starting from initial study of each application and testing the stock parts, a draft design is presented. If the idea is accepted by the responsible team, we 3D CAD design the filter and rapid prototype samples. Then we test the Ark-Filters prototypes and choose one from the best.
            </p>
          </div>
        </div>
      </div>

      <!-- Features Icon-->
      <div class="col-md-4">
        <div class="features-icon-box">

          <div class="features-icon">
            <i class="fa fa-wrench"></i>
          </div>

          <div class="features-info">
            <h4>Mold, Tooling and Machining</h4>
            <p>NC files from R&D are loaded to the CNC machines and all the necessary molds and tools are produced. Following, the molds are transferred to our general machine shop for finishing all the necessary details, to be cleaned and assembled. Next, the molds are transferred to the filter production department, including all the "jigs" and fixtures that are necessary for the production.
            </p>
          </div>
        </div>
      </div>

      <!-- Features Icon-->
      <div class="col-md-4">
        <div class="features-icon-box">

          <div class="features-icon">
            <i class="fa fa-life-saver"></i>
          </div>

          <div class="features-info">
            <h4>Filtering Media Manufacturing</h4>
            <p>This department produces the famous DNA 4 layer nafta cotton filtering media, at the necessary size for each Ark-filter. The material is then slitted and cut to the designed sizes and patterns. This is a unique patented procedure that we cannot show. All the ready cut filtering material is quality controlled and forwarded to the filter production department.
            </p>
          </div>
        </div>
      </div>

      <!-- Features Icon-->
      <div class="col-md-4">
        <div class="features-icon-box">

          <div class="features-icon">
            <i class="fa fa-cogs"></i>
          </div>

          <div class="features-info">
            <h4>Filter Production</h4>
            <p>The filter production department is also responsible for placing all the necessary parts on the filter, for example gaskets, spacers, bolts, locating tabs etc. Following the production batch goes through quality control and all the filters that pass, are forwarded to the oiling stations. One robotic and two PLC controlled machines will complete the task with precision. Next, the filters are forwarded to the packaging department.
            </p>
          </div>
        </div>
      </div>

      <!-- Features Icon-->
      <div class="col-md-4">
        <div class="features-icon-box">

          <div class="features-icon">
            <i class="fa fa-gift"></i>
          </div>

          <div class="features-info">
            <h4>Packaging</h4>
            <p>At this stage all the Ark-Filters products are packed in their dedicated boxes, including tech & installation info, plus all necessary documentation and parts. Additionally, during the packaging procedure all items pass the final quality control.
            </p>
          </div>
        </div>
      </div>

      <!-- Features Icon-->
      <div class="col-md-4">
        <div class="features-icon-box">

          <div class="features-icon">
            <i class="fa fa-paper-plane"></i>
          </div>

          <div class="features-info">
            <h4>Warehouse, Orders Dispatch</h4>
            <p>Packed orders are placed here, pass through a final check, weight and volume is confirmed and shipped to our valued customers worldwide.
            </p>
          </div>
        </div>
      </div>


    </div> <!-- /.row-->
  </div> <!-- /.container-->
</section>
<!--End Features-->
