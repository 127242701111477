
<!-- Start Slider  -->
<section id="home" class="home">
  <div class="slider-overlay"></div>
  <div class="flexslider">
    <ul class="slides scroll">
      <li class="first">
        <div class="slider-text-wrapper">
          <div class="container">
            <div class="big">WE DON’T MAKE FILTERS ONLY, BUT ENGINEERS THEM UPTO THE HIGHEST QUALITY LEVEL </div>
            <div class="small">Have a look into our Products</div>
            <a href="#works" class="middle btn btn-white">VIEW PORTFOLIO</a>
          </div>
        </div>
        <img src="../../../assets/images/slider/1.jpeg" alt="">
      </li>

      <li class="secondary">
        <div class="slider-text-wrapper">
          <div class="container">
            <div class="big">ARK-FILTERS NEVER SETTLES LESS THAN THE BEST </div>
            <div class="small">See what we offer and Let us to know</div>
            <a href="#works" class=" middle btn btn-white">VIEW PORTFOLIO</a>
          </div>
        </div>
        <img src="../../../assets/images/slider/2.jpg" alt="">
      </li>

      <li class="third">
        <div class="slider-text-wrapper">
          <div class="container">
            <div class="big">ONCE ARK-FILTERS, ALWAYS ARK-FILTERS. </div>
            <div class="small">Our products make us proud</div>
            <a href="#works" class="middle btn btn-white">VIEW PORTFOLIO</a>
          </div>
        </div>
        <img src="../../../assets/images/slider/3.jpeg" alt="">
      </li>
    </ul>
  </div>
</section>
<!-- End Slider  -->
