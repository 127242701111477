
<!-- Start Facts-->
<section id="facts" class="section parallax">
  <div class="overlay"></div>
  <div class="container">
    <div class="row">

      <!-- Start Item-->
      <div class="col-md-3 col-sm-6 facts-box margin-bottom-30">
        <span><i class="icon-happy"></i></span>
        <h3>841</h3>
        <span>Happy Clients</span>
      </div>
      <!-- End Item-->

      <!-- Start Item-->
      <div class="col-md-3 col-sm-6 facts-box margin-bottom-30">
        <span><i class="icon-presentation"></i></span>
        <h3>800</h3>
        <span>Projects</span>
      </div>
      <!-- End Item-->

      <!-- Start Item-->
      <div class="col-md-3 col-sm-6 facts-box margin-bottom-30">
        <span><i class="icon-target"></i></span>
        <h3>1232</h3>
        <span>Lines of Code</span>
      </div>
      <!-- End Item-->

      <!-- Start Item-->
      <div class="col-md-3 col-sm-6 facts-box margin-bottom-30">
        <span><i class="icon-trophy"></i></span>
        <h3>300</h3>
        <span>Awards Won</span>
      </div>
      <!-- End Item-->

    </div> <!-- /.row -->
  </div> <!-- /.container -->
</section>
<!--End Facts-->
