<!--Start Skills-->
<section id="skills" class="section parallax">
  <div class="overlay"></div>
  <div class="container">
    <div class="row">

      <div class="title-box text-center white">
        <h2 class="title">Our Workbench</h2>
      </div>

      <!--Skill #1-->
      <div class="col-sm-3 col-md-3 col-lg-3 pie-chart-main">
        <span class="pie-chart " data-percent="10">
          <span class="percent"></span>
        </span>
        <h4>Queued</h4>
        <p>We have number of our projects in queue.</p>
      </div>

      <!--Skill #2-->
      <div class="col-sm-3 col-md-3 col-lg-3 pie-chart-main">
        <span class="pie-chart" data-percent="40">
          <span class="percent"></span>
        </span>
        <h4>In Progress</h4>
        <p>currenlty we have workload for in progress projects.</p>
      </div>

      <!--Skill #3-->
      <div class="col-sm-3 col-md-3 col-lg-3 pie-chart-main">
        <span class="pie-chart " data-percent="30">
          <span class="percent"></span>
        </span>
        <h4>Completed</h4>
        <p>We have done with our workload.</p>
      </div>

      <!--Skill #4-->
      <div class="col-sm-3 col-md-3 col-lg-3 pie-chart-main">
        <span class="pie-chart" data-percent="20">
          <span class="percent"></span>
        </span>
        <h4>Dispatched</h4>
        <p>Successfully delivered to our clients.</p>
      </div>


    </div> <!-- /.row-->
  </div> <!-- /.container-->
</section>
<!--End Skills-->
