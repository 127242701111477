
<app-header-section></app-header-section>
<app-slider-section></app-slider-section>
<app-feature-section></app-feature-section>
<!-- <app-fact-section></app-fact-section> -->
<app-offer-section></app-offer-section>
<app-history-section></app-history-section>
<app-portfolio-section></app-portfolio-section>

<!--Start Call To Action-->
<section id="cta" class="parallax">
  <div class="overlay"></div>
  <div class="container">
    <div class="row text-center">
      <h2>Do you want to get any service from us?</h2>
      <a href="#contact" class="btn btn-green">GET A FREE QUOTE</a>
    </div>
  </div>
</section>
<!--End Call To Action-->

<app-team-section></app-team-section>
<app-skill-section></app-skill-section>
<app-service-section></app-service-section>
<app-video-section></app-video-section>
<!-- <app-blog-section></app-blog-section> -->
<app-testimonial-section></app-testimonial-section>
<app-client-section></app-client-section>
<app-contact-section></app-contact-section>
<app-footer-section></app-footer-section>


