<!--Start Why Choose us-->
<section id="why-choose" class="section">
  <div class="container">
    <div class="row">

      <div class="title-box text-center">
        <h2 class="title">Why Choose us</h2>
      </div>

      <!--start tabs-->
      <div class="col-md-6">
        <div class="tabs tabs-main">
          <ul class="nav nav-tabs">
            <li class="active"><a href="#one" data-toggle="tab">Design</a></li>
            <li><a href="#two" data-toggle="tab">Company</a></li>
            <li><a href="#three" data-toggle="tab">Support</a></li>
          </ul>
          <div class="tab-content">

            <!--Start Tab Item #1 -->
            <div class="tab-pane in active" id="one">
              <p>
                Lorem ipsum dolor consectetur adipisicing incididunt eiusmod tempor incididunt laboredolore magna
                aliqua. Lorem ipsum dolor consectetur adipisicing incididunt eiusmod tempor incididunt laboredolore
                magna aliqua. Lorem ipsum dolor consectetur adipisicing incididunt eiusmod tempor incididunt
                laboredolore magna aliqua. Lorem ipsum dolor consectetur adipisicing incididunt eiusmod tempor
                incididunt laboredolore magna aliqua.
              </p>
            </div>
            <!-- End Tab -->

            <!--Start Tab Item #2 -->
            <div class="tab-pane" id="two">
              <p>
                Lorem ipsum dolor consectetur adipisicing incididunt eiusmod tempor incididunt laboredolore magna
                aliqua. Lorem ipsum dolor consectetur adipisicing incididunt eiusmod tempor incididunt laboredolore
                magna aliqua. Lorem ipsum dolor consectetur adipisicing incididunt eiusmod tempor incididunt
                laboredolore magna aliqua.
              </p>
            </div>
            <!-- End Tab -->

            <!--Start Tab Item #3 -->
            <div class="tab-pane" id="three">
              <p>
                Lorem ipsum dolor consectetur adipisicing incididunt eiusmod tempor incididunt laboredolore magna
                aliqua. Lorem ipsum dolor consectetur adipisicing incididunt eiusmod tempor incididunt laboredolore
                magna aliqua.
              </p>
            </div>
            <!-- End Tab -->

          </div>
        </div>
      </div>
      <!-- End Tabs-->

      <!--Start Accordion-->
      <div class="col-md-6">
        <div class="panel-group accordion-main" id="accordion">
          <!--About accordion #1-->
          <div class="panel">
            <div class="panel-heading collapsed" data-toggle="collapse" data-parent="#accordion"
              data-target="#collapseOne">
              <h6 class="panel-title accordion-toggle">
                Photography
              </h6>
            </div>
            <div id="collapseOne" class="panel-collapse collapse">
              <div class="panel-body">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non blandit nibh. Sed eget tortor
                  tincidunt, auctor sem eget, mollis nisi. Pellentesque ipsum erat, facilisis ut venenatis eu, sodales
                  vel dolor fusce diam ornare.
                </p>

              </div>
            </div>
          </div>

          <!--About accordion #2-->
          <div class="panel">
            <div class="panel-heading collapsed" data-toggle="collapse" data-parent="#accordion"
              data-target="#collapseTwo">
              <h6 class="panel-title accordion-toggle">
                Web Designing
              </h6>
            </div>
            <div id="collapseTwo" class="panel-collapse collapse">
              <div class="panel-body">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non blandit nibh. Sed eget tortor
                  tincidunt, auctor sem eget, mollis nisi. Pellentesque ipsum erat, facilisis ut venenatis eu, sodales
                  vel dolor fusce diam ornare.
                </p>
              </div>
            </div>
          </div>

          <!--About accordion #3-->
          <div class="panel">
            <div class="panel-heading collapsed" data-toggle="collapse" data-parent="#accordion"
              data-target="#collapseThree">
              <h6 class="panel-title accordion-toggle">
                Web Development
              </h6>
            </div>
            <div id="collapseThree" class="panel-collapse collapse">
              <div class="panel-body">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non blandit nibh. Sed eget tortor
                  tincidunt, auctor sem eget, mollis nisi. Pellentesque ipsum erat, facilisis ut venenatis eu, sodales
                  vel dolor fusce diam ornare.
                </p>

              </div>
            </div>
          </div>

          <!--About accordion #4-->
          <div class="panel">
            <div class="panel-heading collapsed" data-toggle="collapse" data-parent="#accordion"
              data-target="#collapseFour">
              <h6 class="panel-title accordion-toggle">
                Responsive Design
              </h6>
            </div>
            <div id="collapseFour" class="panel-collapse collapse">
              <div class="panel-body">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non blandit nibh. Sed eget tortor
                  tincidunt, auctor sem eget, mollis nisi. Pellentesque ipsum erat, facilisis ut venenatis eu, sodales
                  vel dolor fusce diam ornare.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--End Accordion-->

    </div>
    <!--/.row-->
  </div>
  <!--/.container-->
</section>
<!--End Why Choose us-->
