<!--Start Contact-->
<section id="contact" class="section parallax">
  <div class="overlay"></div>
  <div class="container">
    <div class="row">

      <div class="text-center title-box white">
        <h2 class="title">Contact</h2>
      </div>
    </div>

    <!--Start contact form-->
    <div class="col-md-8 col-md-offset-2 contact-form">
      <div class="text-center contact-info">
        <p style="font-weight: bold;">ARK FILTERS</p>
        <p style="font-weight: bold;">S.A.ENGINEERING COMPANY.</p>
        <p>Contacted Person: Eng. Muhammad Aamer</p>
        <p>Sales Office: Lahore Pakistan - 5400</p>
        <p>Email Address: <span>saenggco@yahoo.com</span><span>, </span><span>saenggco@hotmail.com</span></p>
        <p>Mobile No. <span> +92-300-4683138 </span><span>, </span><span>+92-300-6442049</span></p>
        <p>Phone No. <span>+92-42-36556028</span> </p>
      </div>

      <form method="post">
        <div class="row">
          <div class="col-md-4">
            <input class="form-control white-font" id="name" placeholder="Full Name" type="text">
          </div>
          <div class="col-md-4">
            <input class="form-control white-font" id="email" placeholder="Your Email" type="email">
          </div>
          <div class="col-md-4">
            <input class="form-control white-font" id="subject" placeholder="Subject" type="text">
          </div>
          <div class="col-md-12">
            <textarea class="form-control white-font" id="message" rows="7" placeholder="Your Message"></textarea>
          </div>
          <div class="text-right col-md-12">
            <button type="submit" class="btn btn-green">SEND MESSAGE</button>
          </div>
        </div>
      </form>
    </div>
    <!--End contact form-->

  </div> <!-- /.container-->
</section>
<!--End Contact-->

