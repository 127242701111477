
<!--Start video-->
<section id="video" class="section parallax">
  <div class="overlay"></div>
  <div class="container">
    <div class="row">

      <div class="title-box text-center white">
        <h2 class="title">Video</h2>
      </div>

      <div class="col-md-6">
        <div class="video-caption-main">
          <!--Video caption #1-->
          <div class="video-caption">
            <div class="video-icon">
              <i class="fa fa-briefcase"></i>
            </div>
            <div class="video-caption-info">
              <h4>Take a look at this video to see how we work.</h4>
              <p>S.A.ENGINEERING CO. has all the needed technical stuffs to manufacture and develop new products.</p>
            </div>
          </div>

          <!--Video caption #2-->
          <div class="video-caption">
            <div class="video-icon">
              <i class="fa fa-glass"></i>
            </div>
            <div class="video-caption-info">
              <h4>We deliver what we commit with customers.</h4>
              <p>
                We believe our customer should be aware about the things which are currently producing.</p>
            </div>
          </div>

          <!--Video caption #3-->
          <div class="video-caption">
            <div class="video-icon">
              <i class="fa fa-rocket "></i>
            </div>
            <div class="video-caption-info">
              <h4>Fully Equiped production house.</h4>
              <p>We have all the things that are required to develop a good qaulity filters.</p>
            </div>
          </div>

        </div>
      </div>
      <!-- End Video caption-->

      <div class="col-md-6">
        <div class="play-video">
          <iframe src="http://player.vimeo.com/video/115919099?title=0&amp;byline=0&amp;portrait=0&amp;color=fff"
            allowfullscreen></iframe>
        </div>
      </div>

    </div> <!-- /.row-->
  </div> <!-- /.container-->
</section>
<!--End video-->
