
<!-- Start blog-->
<section id="blog" class="section">
  <div class="container">
    <div class="row">

      <div class="title-box text-center">
        <h2 class="title">Latest Blog</h2>
      </div>

      <!-- Start Blog item #1-->
      <div class="col-md-4">
        <div class="blog-post">
          <div class="post-media">
            <img src="../../../assets/images/blog/blog1.jpg" alt="">
          </div>
          <div class="post-desc">
            <h4>consectetur adipisicing Inventore</h4>
            <h5>12 May, 2015 / 5 Comments</h5>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore, dolorum, fugiat, eligendi magni
              quibusdam iure cupiditate ex voluptas unde</p>
            <a href="blog.html" class="btn btn-gray-border">Read More</a>
          </div>
        </div>
      </div>

      <!-- Start Blog item #2-->
      <div class="col-md-4">
        <div class="blog-post">
          <div class="post-media">
            <img src="../../../assets/images/blog/blog2.jpg" alt="">
          </div>
          <div class="post-desc">
            <h4>consectetur adipisicing Inventore</h4>
            <h5>12 May, 2015 / 3 Comments</h5>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore, dolorum, fugiat, eligendi magni
              quibusdam iure cupiditate ex voluptas unde</p>
            <a href="blog.html" class="btn btn-gray-border">Read More</a>
          </div>
        </div>
      </div>

      <!-- Start Blog item #3-->
      <div class="col-md-4">
        <div class="blog-post">
          <div class="post-media">
            <img src="../../../assets/images/blog/blog3.jpg" alt="">
          </div>
          <div class="post-desc">
            <h4>consectetur adipisicing Inventore</h4>
            <h5>12 May, 2015 / 11 Comments</h5>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore, dolorum, fugiat, eligendi magni
              quibusdam iure cupiditate ex voluptas unde</p>
            <a href="blog.html" class="btn btn-gray-border">Read More</a>
          </div>
        </div>
      </div>

    </div>
    <!--/.row-->
  </div>
  <!--/.container-->
</section>
<!-- End blog-->

