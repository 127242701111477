<!--Start History-->
<section id="history" class="section parallax">
  <div class="overlay"></div>
  <div class="container">

    <div class="text-center title-box white">
      <h2 class="title">Company Profile</h2>
    </div>

    <!-- History Timeline -->
    <ul class="timeline list-unstyled">

      <li class="year"></li>

      <!--History Item -->
      <li class="timeline-item">
        <h4>Company Profile</h4>
        <p>S.A ENGINEERING COMPANY is one of the largest filter manufacturing company in PAKISTAN. S.A. ENGINEERING CO. can manufacture as well as can TRADE in the FILTERS. It has a vast range of the filter products. </p>
        <p>ARK FILTERS are manufactured under the manufacturing facility of the MICRO-TECH AUTOMOTIVE INDUSTRY. ROCK-ONE FILTERS and MYCRO-TECH FILTERS are manufactured by S.A. ENGINEERING COMPANY.</p>
        <p>S.A. ENGINEERING COMPANY and MICRO-TECH AUTOMOTIVE INDUSTRY are two SISTER CONCERNS. S.A. ENGINEERING CO. was established by a team of professionals who have the studies of FILTER ENGINEERING.</p>
        <p>They decided to build up a company which can cope with the modern technical needs of the country in the FILTRATION field. Thus their quest for the achievement of the highest quality filter products laid its foundations. S.A. </p>
        <p>ENGINEERING COMPANY has all the needed infrastructure and machinery for developing and testing of the filters. S.A. ENGINEERING COMPANY has a diversified FILTER range, that is, AIR, OIL, FUEL, HYDURALIC, AIR/OIL SEPARATOR, HVAC, PANEL, STRAINERS are to name a few.</p>
      </li>
      <!-- End Item -->

      <!--History Item -->
      <li class="timeline-item">
        <h4>Director Message</h4>
        <p>I, Engineer Muhammad Aamer, am a professional Engineer. I have a dream of developing a Company which can meet the all required filters needs of my country. I want to take the Pakistan Filter Industry at the highest level by manufacturing and Engineering the FILTERS. My company is making enormous achievements in Filtration Fields with the help of my team.</p>
        <p>ARK FILTERS is the base product of the Company. I have tried to manufacture the prime quality Products with my team of professionals by Research and Development. R&D is the basics of the Company. I always emphasis on developing no matter how tough is the job.</p>
        <p>I believe in customer satisfaction and always take customers as our asset.</p>
        <p>I look forward to having cordial business relations and serving you better at all times.</p>
        <p class="md">Engr. Muhammad Aamer</p>
        <p class="md">Managing Director</p>
      </li>
      <!-- End Item -->

      <!-- History Year -->
      <li class="year"></li>


      <!--History Item -->
      <li class="timeline-item">
        <h4>Vision</h4>
        <p>ARK FILTER IS ALWAYS looks for the best engineered products for its valued customers. We can manage to take this filtration field up to the highest level, so to go beyond its limitations.</p>
      </li>
      <!-- End Item -->

      <!--History Item -->
      <li class="timeline-item">
        <h4>Mission</h4>
        <p>Our mission comprises on the following point to achieve</p>
        <ul style="color: white;">
          <li class="li-m10">Quality products at lowest cost</li>
          <li class="li-m10">Filter engineering</li>
          <li class="li-m10">Flexible mode for our customers</li>
          <li class="li-m10">Always listen to the needs of customers</li>
          <li class="li-m10">Consulting</li>
          <li class="li-m10">Optimum performance</li>
        </ul>
      </li>
      <!-- End Item -->

      <!--History Item -->
      <!-- <li class="timeline-item">
        <h4>Lorem ipsum dolor consectetur adipisicing.</h4>
        <p>Lorem ipsum dolor consectetur adipisicing incididunt eiusmod tempor incididunt laboredolore magna aliqua.</p>
        <span> 18 OCT 2015</span>
      </li> -->
      <!-- End Item -->

      <li class="clear"></li>

      <li class="end-icon fa fa-bookmark"></li>
    </ul>
    <!-- End History Timeline -->

  </div>
  <!--/.container-->
</section>
<!--End History-->
