
<!--Start clients-->
<section id="clients" class="section">
  <div class="container">
    <div class="row">

      <div class="title-box text-center">
        <h2 class="title">Our Clients</h2>
      </div>

      <div class="clients-carousel">

        <!-- Clients Logo Item-->
        <div class="item">
          <a href="#">
            <figure>
              <img src="../../../assets/images/clients-logo/client1.png" alt="" />
            </figure>
          </a>
        </div>

        <!-- Clients Logo Item-->
        <div class="item">
          <a href="#">
            <figure>
              <img src="../../../assets/images/clients-logo/client2.png" alt="" />
            </figure>
          </a>
        </div>

        <!-- Clients Logo Item-->
        <div class="item">
          <a href="#">
            <figure>
              <img src="../../../assets/images/clients-logo/client3.png" alt="" />
            </figure>
          </a>
        </div>

        <!-- Clients Logo Item-->
        <div class="item">
          <a href="#">
            <figure>
              <img src="../../../assets/images/clients-logo/client4.png" alt="" />
            </figure>
          </a>
        </div>

        <!-- Clients Logo Item-->
        <div class="item">
          <a href="#">
            <figure>
              <img src="../../../assets/images/clients-logo/client2.png" alt="" />
            </figure>
          </a>
        </div>

        <!-- Clients Logo Item-->
        <div class="item">
          <a href="#">
            <figure>
              <img src="../../../assets/images/clients-logo/client5.png" alt="" />
            </figure>
          </a>
        </div>

        <!-- Clients Logo Item-->
        <div class="item">
          <a href="#">
            <figure>
              <img src="../../../assets/images/clients-logo/client4.png" alt="" />
            </figure>
          </a>
        </div>

        <!-- Clients Logo Item-->
        <div class="item">
          <a href="#">
            <figure>
              <img src="../../../assets/images/clients-logo/client1.png" alt="" />
            </figure>
          </a>
        </div>
      </div>
    </div>
    <!--/.row-->
  </div>
  <!--/.container-->
</section>
<!--End clients-->
