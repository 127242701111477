import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SliderSectionComponent } from './app-component/slider-section/slider-section.component';
import { FeatureSectionComponent } from './app-component/feature-section/feature-section.component';
import { FactSectionComponent } from './app-component/fact-section/fact-section.component';
import { OfferSectionComponent } from './app-component/offer-section/offer-section.component';
import { HistorySectionComponent } from './app-component/history-section/history-section.component';
import { PortfolioSectionComponent } from './app-component/portfolio-section/portfolio-section.component';
import { TeamSectionComponent } from './app-component/team-section/team-section.component';
import { SkillSectionComponent } from './app-component/skill-section/skill-section.component';
import { ServiceSectionComponent } from './app-component/service-section/service-section.component';
import { ProfileSectionComponent } from './app-component/profile-section/profile-section.component';
import { VideoSectionComponent } from './app-component/video-section/video-section.component';
import { BlogSectionComponent } from './app-component/blog-section/blog-section.component';
import { TestimonialSectionComponent } from './app-component/testimonial-section/testimonial-section.component';
import { ClientSectionComponent } from './app-component/client-section/client-section.component';
import { ContactSectionComponent } from './app-component/contact-section/contact-section.component';
import { FooterSectionComponent } from './app-component/footer-section/footer-section.component';
import { HeaderSectionComponent } from './app-component/header-section/header-section.component';

@NgModule({
  declarations: [
    AppComponent,
    SliderSectionComponent,
    FeatureSectionComponent,
    FactSectionComponent,
    OfferSectionComponent,
    HistorySectionComponent,
    PortfolioSectionComponent,
    TeamSectionComponent,
    SkillSectionComponent,
    ServiceSectionComponent,
    ProfileSectionComponent,
    VideoSectionComponent,
    BlogSectionComponent,
    TestimonialSectionComponent,
    ClientSectionComponent,
    ContactSectionComponent,
    FooterSectionComponent,
    HeaderSectionComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
