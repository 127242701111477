<!--Start Services-->
<section id="services" class="section">
  <div class="container">
    <div class="row">

      <div class="title-box text-center">
        <h2 class="title">Capabilities</h2>
      </div>

      <!--Services Item-->
      <div class="col-md-4">
        <div class="services-box">
          <div class="services-icon"> <i class="icon-basket"></i> </div>
          <div class="services-desc">
            <h4>Manufacturing</h4>
            <p>ARK FILTERS is a COMPANY with all the required machines and man power of manufacturing capability</p>
            <p>Our team are specialized in developing air purifiers and air humidifier with professional acknowledge and
              technology with more than 10 years experiences. We have marketing team to do marketing research and
              realize customers requirement. Therefore, our customers pay less for trying and can be more profitable and
              more successful.</p>
            <p class="text-left">In-house capabilities include:</p>
            <ul class="text-left">
              <li>
                <p class="m-b-0">Sophisticated media handing</p>
              </li>
              <li>
                <p class="m-b-0">Metal fabrication and coatings</p>
              </li>
              <li>
                <p class="m-b-0">Plastic injection molding</p>
              </li>
              <li>
                <p class="m-b-0">Ultrasonic welding</p>
              </li>
              <li>
                <p class="m-b-0">Complex assembly</p>
              </li>
              <li>
                <p class="m-b-0">Unit & bulk packaging</p>
              </li>
              <li><p class="m-b-0">Product Isolation</p></li>
              <li><p class="m-b-0">Pleated Length Controlled</p></li>
              <li><p class="m-b-0">Both Ends Thermal Welded</p></li>
              <li><p class="m-b-0">End Cap Connected</p></li>

            </ul>
          </div>
        </div>
      </div>
      <!--End services Item-->

      <!--Services Item-->
      <div class="col-md-4">
        <div class="services-box">
          <div class="services-icon"> <i class="icon-global"></i> </div>
          <div class="services-desc">
            <h4>Trading</h4>
            <p>For our valued customers we believe in providing of all the required Filters demand. As for their demand
              for the availability of all the well renowned branded filters we have arranged the most of the brands at
              one place,that is, S A Engineering Company. Mostly of these filters fall in the category which are too low
              in demand that these filters are not feasible for manufacturing. Actually these are Specially designed
              filters for special machines, that is Compressores, Generators, HVAC Plants, Compressed air line and Heavy
              duty Application. We have the capability to provide you these products at competitive rates in the Local
              Markets.</p>
            <p class="m-b-0 text-left">Hereunder are the some of the brands of filters:</p>
            <ul class="text-left">
              <li>
                <p class="m-b-0">Mann-Filters Germany</p>
              </li>
              <li>
                <p class="m-b-0">FleetGurd Filters USA</p>
              </li>
              <li>
                <p class="m-b-0">Micropore Turkey</p>
              </li>
              <li>
                <p class="m-b-0">Baldwain Filters USA</p>
              </li>
              <li>
                <p class="m-b-0">Sure Filters Indonisia</p>
              </li>
              <li>
                <p class="m-b-0">KelTech USA</p>
              </li>
              <li>
                <p class="m-b-0">Noitech Germany</p>
              </li>
              <li>
                <p class="m-b-0">Sotras Europe</p>
              </li>
              <li>
                <p class="m-b-0">Donaldson Filters</p>
              </li>
              <p class="m-b-0">&nbsp;</p>
            </ul>
          </div>
        </div>
      </div>
      <!--End services Item-->

      <!--Services Item-->
      <div class="col-md-4">
        <div class="services-box">
          <div class="services-icon"> <i class="icon-hotairballoon"></i> </div>
          <div class="services-desc">
            <h4>Engineering</h4>
            <p>Our team are specialized in developing air purifiers and air humidifier with professional acknowledge and
              technology with more than 10 years experiences. We have marketing team to do marketing research and
              realize customers requirement. Therefore, our customers pay less for trying and can be more profitable and
              more successful.</p>
              <p>We have the experience and knowledge to be a cost-effective single-source supplier for all types of filtration. We source filtration equipment and replacement filters for all applications.</p>
              <p>Don’t "spoil the coil". Work with our team for a solution preventing particulates that travel into facility heating and air conditioning systems. Creating the best indoor air quality is of the highest importance for the maximum comfort of all facilities.</p>
              <p>More importantly we assist our customers in the development of the optimal filtration strategy for each individual application, this is how we drive down the total cost of ownership.</p>
          </div>
        </div>
      </div>
      <!--End services Item-->

      <!--Services Item -->
      <div class="col-md-4">
        <div class="services-box">
          <div class="services-icon"> <i class="icon-shield"></i> </div>
          <div class="services-desc">
            <h4>Testing</h4>
            <p>ARK FILTER has great believe in testing of products before delivering to the customers. ARK FILTER tests
              products with the latest technology to detect leaks, conductivity, efficiency, and performance against
              specifications. </p>
            <p>ARK FILTER has great believe in testing of products before delivering to the customers. ARK FILTER tests
              products with the latest technology to detect leaks, conductivity, efficiency, and performance against
              specifications. </p>
              <p>&nbsp;</p>
              <p class="m-b-0">&nbsp;</p>
          </div>
        </div>
      </div>
      <!--End services Item-->

      <!--Services Item-->
      <div class="col-md-4">
        <div class="services-box">
          <div class="services-icon"> <i class="icon-target"></i> </div>
          <div class="services-desc">
            <h4>Custom Filters</h4>
            <p>In this era of research and development there is always need for the custom designed filter. </p>
            <p>With the design experience, expertise, equipment, tooling, and media availability – ARK FILTER can meet
              your custom filtration product needs.</p>
            <p>Our team stands ready to help conceptualize, design, test and manufacture filters.</p>
            <p>Whether your product will require spin-on, cartridge, panel or other design standards, we have the
              resources and experience needed to deliver the best solutions.</p>
          </div>
        </div>
      </div>
      <!--End services Item-->

      <!--Services Item -->
      <div class="col-md-4">
        <div class="services-box">
          <div class="services-icon"> <i class="icon-aperture"></i> </div>
          <div class="services-desc">
            <h4>Imported Filters</h4>
            <p>ARK filter has the capability of manufacturing all kind of filters. As some of the major products are
              described with details, but we have many more type of filters. To name a few are the following: </p>
            <ul class="text-left">
              <li>
                <p class="m-b-0">Chemical Liquid Filters</p>
              </li>
              <li>
                <p class="m-b-0">Strainers</p>
              </li>
              <li>
                <p class="m-b-0">Micro Filters</p>
              </li>
              <li>
                <p class="m-b-0">Seperator Filters</p>
              </li>
              <li>
                <p class="m-b-0">Paint Spray Booth Filters</p>
              </li>
              <li>
                <p class="m-b-0">Gas Turbine Air Intake Filters</p>
              </li>
              <li>
                <p class="m-b-0">Many More</p>
              </li>
            </ul>
            <p class="m-b-0">&nbsp;</p>
            <p class="m-b-0">&nbsp;</p>
          </div>
        </div>
      </div>
      <!--End services Item-->

    </div>
    <!--/.row-->
  </div>
  <!--/.container-->
</section>
<!--End Services-->
