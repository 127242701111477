
<!--Start Team-->
<section id="team" class="section">
  <div class="container">
    <div class="row">

      <div class="title-box text-center">
        <h2 class="title">Team</h2>
      </div>

    </div>

    <!-- Team -->
    <div class="team-items team-carousel">
      <!-- Team Member #1 -->
      <div class="item">
        <img src="../../../assets/images/team/team1.jpg" alt="" />
        <h4>Jonh Doe</h4>
        <h5>Photogropher</h5>
        <p>Lorem ipsum dolor consectetur dolor consectetur adipisicing incididunt eiusmod temporcd incididunt
          consectetur laboredolore dolor adipisicing. </p>

        <div class="socials">
          <ul>
            <li><a class="facebook" href="#"><i class="fa fa-facebook"></i></a></li>
            <li><a class="twitter" href="#"><i class="fa fa-twitter"></i></a></li>
            <li><a class="linkedin" href="#"><i class="fa fa-linkedin"></i></a></li>
            <li><a class="google-plus" href="#"><i class="fa fa-google-plus"></i></a></li>
          </ul>
        </div>
      </div>
      <!-- End Member -->

      <!-- Team Member #2 -->
      <div class="item">
        <img src="../../../assets/images/team/team2.jpg" alt="" />
        <h4>Jonh Doe</h4>
        <h5>Web Designer</h5>
        <p>Lorem ipsum dolor consectetur dolor consectetur adipisicing incididunt eiusmod temporcd incididunt
          consectetur laboredolore dolor adipisicing. </p>

        <div class="socials">
          <ul>
            <li><a class="facebook" href="#"><i class="fa fa-facebook"></i></a></li>
            <li><a class="twitter" href="#"><i class="fa fa-twitter"></i></a></li>
            <li><a class="linkedin" href="#"><i class="fa fa-linkedin"></i></a></li>
            <li><a class="google-plus" href="#"><i class="fa fa-google-plus"></i></a></li>
          </ul>
        </div>
      </div>
      <!-- End Member -->

      <!-- Team Member #3 -->
      <div class="item">
        <img src="../../../assets/images/team/team3.jpg" alt="" />
        <h4>Jonh Doe</h4>
        <h5>Graphic Designer</h5>
        <p>Lorem ipsum dolor consectetur dolor consectetur adipisicing incididunt eiusmod temporcd incididunt
          consectetur laboredolore dolor adipisicing. </p>

        <div class="socials">
          <ul>
            <li><a class="facebook" href="#"><i class="fa fa-facebook"></i></a></li>
            <li><a class="twitter" href="#"><i class="fa fa-twitter"></i></a></li>
            <li><a class="linkedin" href="#"><i class="fa fa-linkedin"></i></a></li>
            <li><a class="google-plus" href="#"><i class="fa fa-google-plus"></i></a></li>
          </ul>
        </div>
      </div>
      <!-- End Member -->

      <!-- Team Member #4 -->
      <div class="item">
        <img src="../../../assets/images/team/team4.jpg" alt="" />
        <h4>Jonh Doe</h4>
        <h5>Photogropher</h5>
        <p>Lorem ipsum dolor consectetur dolor consectetur adipisicing incididunt eiusmod temporcd incididunt
          consectetur laboredolore dolor adipisicing. </p>

        <div class="socials">
          <ul>
            <li><a class="facebook" href="#"><i class="fa fa-facebook"></i></a></li>
            <li><a class="twitter" href="#"><i class="fa fa-twitter"></i></a></li>
            <li><a class="linkedin" href="#"><i class="fa fa-linkedin"></i></a></li>
            <li><a class="google-plus" href="#"><i class="fa fa-google-plus"></i></a></li>
          </ul>
        </div>
      </div>
      <!-- End Member -->

      <!-- Team Member #5 -->
      <div class="item">
        <img src="../../../assets/images/team/team1.jpg" alt="" />
        <h4>Jonh Doe</h4>
        <h5>Graphic Designer</h5>
        <p>Lorem ipsum dolor consectetur dolor consectetur adipisicing incididunt eiusmod temporcd incididunt
          consectetur laboredolore dolor adipisicing. </p>

        <div class="socials">
          <ul>
            <li><a class="facebook" href="#"><i class="fa fa-facebook"></i></a></li>
            <li><a class="twitter" href="#"><i class="fa fa-twitter"></i></a></li>
            <li><a class="linkedin" href="#"><i class="fa fa-linkedin"></i></a></li>
            <li><a class="google-plus" href="#"><i class="fa fa-google-plus"></i></a></li>
          </ul>
        </div>
      </div>
      <!-- End Member -->

      <!-- Team Member #6 -->
      <div class="item">
        <img src="../../../assets/images/team/team2.jpg" alt="" />
        <h4>Jonh Doe</h4>
        <h5>Web Designer</h5>
        <p>Lorem ipsum dolor consectetur dolor consectetur adipisicing incididunt eiusmod temporcd incididunt
          consectetur laboredolore dolor adipisicing. </p>

        <div class="socials">
          <ul>
            <li><a class="facebook" href="#"><i class="fa fa-facebook"></i></a></li>
            <li><a class="twitter" href="#"><i class="fa fa-twitter"></i></a></li>
            <li><a class="linkedin" href="#"><i class="fa fa-linkedin"></i></a></li>
            <li><a class="google-plus" href="#"><i class="fa fa-google-plus"></i></a></li>
          </ul>
        </div>
      </div>
      <!-- End Member -->
    </div>
    <!-- End Team -->
  </div>
  <!-- End Content -->
</section>
<!--End Team-->
