import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-portfolio-section',
  templateUrl: './portfolio-section.component.html',
  styleUrls: ['./portfolio-section.component.css'],
})
export class PortfolioSectionComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  allFilters: boolean = true;
  airFilters: boolean = false;
  oilFilters: boolean = false;
  hydraulicFilters: boolean = false;
  panelFilters: boolean = false;
  waterFilters: boolean = false;
  fuelFilters: boolean = false;

  allFiltersFunction() {
    this.allFilters = true;
    this.airFilters = false;
    this.oilFilters = false;
    this.hydraulicFilters = false;
    this.panelFilters = false;
    this.waterFilters = false;
    this.fuelFilters = false;
  }

  airFiltersFunction() {
    this.allFilters = false;
    this.airFilters = true;
    this.oilFilters = false;
    this.hydraulicFilters = false;
    this.panelFilters = false;
    this.waterFilters = false;
    this.fuelFilters = false;
  }

  oilFiltersFunction() {
    this.allFilters = false;
    this.airFilters = false;
    this.oilFilters = true;
    this.hydraulicFilters = false;
    this.panelFilters = false;
    this.waterFilters = false;
    this.fuelFilters = false;
  }

  hydraulicFiltersFunction() {
    this.allFilters = false;
    this.airFilters = false;
    this.oilFilters = false;
    this.hydraulicFilters = true;
    this.panelFilters = false;
    this.waterFilters = false;
    this.fuelFilters = false;
  }

  panelFiltersFunction() {
    this.allFilters = false;
    this.airFilters = false;
    this.oilFilters = false;
    this.hydraulicFilters = false;
    this.panelFilters = true;
    this.waterFilters = false;
    this.fuelFilters = false;
  }

  waterFiltersFunction() {
    this.allFilters = false;
    this.airFilters = false;
    this.oilFilters = false;
    this.hydraulicFilters = false;
    this.panelFilters = false;
    this.waterFilters = true;
    this.fuelFilters = false;
  }

  fuelFiltersFunction() {
    this.allFilters = false;
    this.airFilters = false;
    this.oilFilters = false;
    this.hydraulicFilters = false;
    this.panelFilters = false;
    this.waterFilters = false;
    this.fuelFilters = true;
  }
}
